import laptop from "../../assets/laptop.png"
import design from "../../assets/web-design.png";
import analysis from "../../assets/analysis.png";
import Banner from "../../components/banner/Banner";


const MainPage = () => {
    return (
        <section id="main">
            <Banner title="Welcome to Abtram, where health gains value." />
            <div className="flex flex-col items-center my-12">
                <h2 className="text-center text-4xl mb-4 w-3/5"><strong>We are an information and communication technology and healthcare consulting company.</strong></h2>
                <p className="text-2xl text-center w-3/5">We develop and implement citizen centred health and healthcare applications based on innovative secure data exchange methods, intuitive user interface design and open enterprise architecture. Working together with us you will be satisfied as we guarantee quality results.</p>
            </div>
            <hr />
            <div className="flex flex-col items-center mt-12 w-full">
                <div className="grid grid-cols-1 md:grid-cols-3 w-3/5 gap-8">
                    <div className="flex flex-col items-center">
                        <img src={analysis} alt="analysisicn" className="h-28" />
                        <h3 className="text-2xl mt-5"><strong>Analysis</strong></h3>
                        <p className="mt-5 text-lg text-center">We analyse the most complex situations and problems to provide the best solutions exactly for you.</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img src={design} alt="hearticn" className="h-28" />
                        <h3 className="text-2xl mt-5"><strong>Design</strong></h3>
                        <p className="mt-5 text-lg text-center">We design user friendly, logical and effective software solutions that bring smiles to people’s faces. </p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img src={laptop} alt="laptopicn" className="h-28" />
                        <h3 className="text-2xl mt-5"><strong>Development</strong></h3>
                        <p className="mt-5 text-lg text-center">We develop fast, reliable and safe software that does exactly what it is supposed to and even more.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MainPage;