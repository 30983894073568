const Contact = () => {
    return (
        <section id="contact" className="w-full flex flex-col items-center">
            <div className="w-3/5 mt-12">
                <div className="mt-12">
                    <h2 className="text-6xl mb-36"><strong>STANDARDS</strong></h2>
                    <a className="hover:text-primary" href="/Portaali_ja_labori_integratsiooni_standardite_kirjeldus_v1.docx" download="/Portaali_ja_labori_integratsiooni_standardite_kirjeldus_v1.docx">Portal_and_laboratory_integration_standards_description_v1 (in estonian)</a>
                </div>
                <div className="mt-12">
                    <h2 className="text-6xl mb-36"><strong>CONTACT</strong></h2>
                    <p className="text-3xl">Call, e-mail or visit us at:</p>
                    <div className="w-1/2">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-12">
                            <div className="flex flex-col mt-8">
                                <p>OÜ Abtram29</p>
                                <p>Kaarli pst 9-1a</p>
                                <p>Tallinn, Estonia</p>
                                <p>10119</p>
                            </div>
                            <div className="flex flex-col mt-8">
                                <p className="text-gray-500">Tel: <a href="tel:+37256485978" className="hover:text-primary">+372 56 48 59 78</a></p>
                            
                                <p className="text-gray-500">E-mail: <a href="mailto: info@abtram.eu" className="hover:text-primary">info@abtram.eu</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;