const Footer = () => {
    return (
        <footer className="py-4">
            <hr />
            <div className="container mx-auto text-center mt-3">
                <p className="text-gray-500">
                    Copyright &copy; 2014 Abtram29 OÜ
                </p>
                <p className="text-gray-500">
                    Kõik õigused kaitstud.
                </p>
            </div>
        </footer>
    );
}

export default Footer;