import founder1 from "../../assets/Janek_Metsallik_2014-e1424799519224.jpg";
import founder2 from "../../assets/2013_Ross_Foto.jpg";

const AboutUs = () => {
    return (
        <section id="about-us" className="w-full bg-primary flex flex-col items-center mt-12">
            <div className="w-3/5 text-white mt-12">
                <h2 className="text-7xl"><strong>ABOUT US</strong></h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="flex flex-col mt-12">
                        <h3 className="text-6xl">We create and consult citizen centred health and healthcare services.</h3>
                    </div>
                    <div className="flex flex-col mt-12">
                        <p className="text-xl">Abtram29 is founded by leading specialists in eHealth domain in Estonia, Janek Metsallik and  Peeter Ross.
                            Together with medical advisor Madis Tiik the aim of Abtram29’s team is to develop and implement shared citizen-centred health and healthcare services.
                            The founders use their profound experience in implementation of large scale eHealth projects in public and private sector in Estonia during the last 10 years.</p>
                        <p className="text-xl mt-3">Abtram29’s goal is to accelerate the market entry of customer-centred digital healthcare service by re-engineering the current processes and by integrating different databases.
                            Abtram29 brings the health decision making closer to the people which makes it possible for a person/patient be engaged and proactive in resolving its’ health issues.</p>
                        <p className="text-xl mt-3">Abtram29 develops profiled user portals for citizen and healthcare professionals for health and healthcare data collecting, especially in the field of remote monitoring,
                            for data viewing and mediating shared health related services. With new patient care pathway’s Abtram29 develops customer managed care.
                            Abtram29 core knowledge also includes implementation of electronic clinical decision support systems.</p>
                        <p className="text-xl mt-3">Abtram29 has wide variety of skills and experience in eHealth and telemedicine field for example clinical,
                            IT analysis, software development and programming, software maintenance and health and healthcare IT consulting knowledge are represented.</p>
                    </div>
                </div>
                <h2 className="text-7xl mt-12"><strong>Our references</strong></h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="flex flex-col mt-12">
                        <label className="text-black text-2xl mb-2">Telemedicine solutions</label>
                        <p className="mb-2">Deployment of large-scale telemedicine solutions in Estonia.</p>
                        <p>Analysis that evaluated the need, the possibilities and the preconditions for wider implementation of telemedicine in Estonia with recommendations and strategy development.</p>
                    </div>
                    <div className="flex flex-col mt-12">
                        <label className="text-black text-2xl mb-2">Healthcare applications</label>
                        <p>Customer centric analysis, development and implementation of web-based health and healthcare applications for general practitioners, diagnostic centres and occupational health providers and citizens.</p>
                    </div>
                    <div className="flex flex-col mt-12">
                        <label className="text-black text-2xl mb-2">Data exchange platform</label>
                        <p className="mb-2">Strategic planning of implementation, development and maintenance of the nation-wide Health Information System (EHIS) –
                            the first fully functional national Health Information Exchange (HIE) platform for healthcare professionals and citizens covering the whole country.</p>
                        <p>Implementation of the secure internet based data exchange platform in Espoo, Finland. Piloting of virtual services in Finland based on x-road platform.</p>
                    </div>
                    <div className="flex flex-col mt-12">
                        <label className="text-black text-2xl mb-2">eHealth projects</label>
                        <p className="mb-2">Involvement as eHealth domain experts in EU funded eHealth projects InterregPacs, Baltic eHealth, R-Bay, Dreaming, Regional Telemedicine Forum (RTF), Smart Open Services for European Patients (epSOS), eMedic – Developing New Practices for Teleconsultation and Diabetes, SUSTAINS and European Momentum for Mainstreaming Telemedicine Deployment in Daily Practice (Momentum).</p>
                        <p>Involvement as eHealth consultants in Mongolia e-Health Project for The World Bank, Promoting the Development of Moldovan Health Care System with the Opportunities of E-services for Ministry of Foreign Affairs of Estonia and Moldovan Ministry of Health. Also consulting the Estonian President in the eHealth field and in international eHealth expertgroup work.</p>
                    </div>
                    <div className="flex flex-col mt-12">
                        <label className="text-black text-2xl mb-2">Hospital IS</label>
                        <p>Providing information systems consultations to one of the biggest hospitals in Estonia.</p>
                    </div>
                    <div className="flex flex-col mt-12">

                    </div>
                </div>
                <h2 className="text-7xl mt-24"><strong>Meet the founders</strong></h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mx-5 mb-12">
                    <div className="flex flex-col items-center mt-12">
                        <img src={founder1} alt="founder1" className="mb-5 w-56 h-56 rounded-full overflow-hidden object-cover transition duration-300 border-4 border-transparent hover:border-white" />
                        <label><strong>Janek Metsallik</strong></label>
                        <p className="text-xs">Founder, Chief Architect</p>
                    </div>
                    <div className="flex flex-col items-center mt-12">
                        <img src={founder2} alt="founder2" className="mb-5 w-56 h-56 rounded-full overflow-hidden object-cover transition duration-300 border-4 border-transparent hover:border-white" />
                        <label><strong>Peeter Ross</strong></label>
                        <p className="text-xs">Founder</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;