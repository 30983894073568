import AboutUs from "../about-us/AboutUs";
import Services from "../services/Services";
import Contact from "../contact/Contact";
import MainPage from "../main-page/MainPage";


const Home = () => {
    return (
        <div>
            <MainPage />

            <AboutUs />
            
            <Services />
            
            <Contact />
        </div>
    );
}

export default Home;