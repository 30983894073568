import logo from "../../assets/abtram logo.png";
import Nav from "./Nav";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
    return (
        <header className="w-full sticky top-0 bg-white shadow-md z-10 h-28">
            <div className="container mx-auto max-w-screen-lg px-4 flex justify-between">
                <div className="flex-shrink-0">
                    <ScrollLink
                        to="main"
                        smooth={true}
                        duration={500}
                        offset={-112}
                        spy={true}
                    >
                        <img src={logo} alt="Logo" className="h-14 mt-3" />
                    </ScrollLink>
                    
                </div>
                <Nav />
            </div>
        </header>
    );
}

export default Header;