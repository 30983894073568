import laptop from "../../assets/laptop.png"
import analysis from "../../assets/analysis.png";
import consultation from "../../assets/conversation.png";
import design from "../../assets/web-design.png";
import healthcare from "../../assets/healthcare.png";
import customerService from "../../assets/customer-service.png";

const Services = () => {
    return (
        <section id="services" className="w-full bg-gray-200 flex flex-col items-center">
            <div className="w-3/5 mt-24">
                <h2 className="text-7xl"><strong>SERVICES</strong></h2>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                    <div className="flex flex-col mt-12 col-span-2">
                        <h3 className="text-5xl">We create applications and platforms to give value to health.</h3>
                        <h5 className="text-2xl text-primary">If you want to break boundaries with your project, working with us is a good start.</h5>
                    </div>
                    <div className="flex flex-col md:col-span-1 col-span-2 mt-12">
                        <p>Abtram has two main focus areas:</p>
                        <p className="mt-3">Customer centred analysis, development and implementation of web-based health and healthcare applications for general practitioners, diagnostic centres, occupational health providers and citizens.</p>
                        <p className="mt-3">Implementation of secure web-based data exchange platform.</p>
                    </div>
                </div>
                <h3 className="text-7xl">Our Services</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-12">
                    <div className="flex flex-col items-center mt-8">
                        <img src={consultation} alt="consulticn" className="h-28" />
                        <h3 className="text-2xl mt-5"><strong>Clinical consultation</strong></h3>
                        <p className="mt-5 text-lg text-center">Can’t design a clinical system without doctors input. Our doctors know how to connect clinical needs with technological capabilities.</p>
                    </div>
                    <div className="flex flex-col items-center mt-8">
                        <img src={analysis} alt="analysis2" className="h-28" />
                        <h3 className="text-2xl mt-5"><strong>IT analysis</strong></h3>
                        <p className="mt-5 text-lg text-center">Analyses is needed before the development to understand what and how to do and choose the right path.  After the development it is needed to stay on the right course.</p>
                    </div>
                    <div className="flex flex-col items-center mt-8">
                        <img src={laptop} alt="laptop2icn" className="h-28" />
                        <h3 className="text-2xl mt-5"><strong>Software development</strong></h3>
                        <p className="mt-5 text-lg text-center">Success lies in user friendly, smart and efficient software – we do that the right and elegant way.</p>
                    </div>
                    <div className="flex flex-col items-center mt-8">
                        <img src={design} alt="designicn" className="h-28" />
                        <h3 className="text-2xl mt-5"><strong>Design</strong></h3>
                        <p className="mt-5 text-lg text-center">Before development  it is crucial to understand how everything should work. We will design the software accordingly the right and the smart way.</p>
                    </div>
                    <div className="flex flex-col items-center mt-8">
                        <img src={healthcare} alt="healthcareicn" className="h-28" />
                        <h3 className="text-2xl mt-5"><strong>Healthcare IT consultation</strong></h3>
                        <p className="mt-5 text-lg text-center">Sometimes you only need some consultation from the experienced people to do the next steps – we are always here to help.</p>
                    </div>
                    <div className="flex flex-col items-center mt-8">
                        <img src={customerService} alt="serviceicn" className="h-28" />
                        <h3 className="text-2xl mt-5"><strong>Support and maintainance</strong></h3>
                        <p className="mt-5 text-lg text-center">We don’t build and run, we want to build and grow together. Our aim is to be partners and change the healthcare scene together.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;