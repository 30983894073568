
import banner from "../../assets/Mees-metsas-pixelmator_Fotor.jpg";

interface BannerProps {
    title: string
}

const Banner: React.FC<BannerProps> = ({ title }) => {
    return (
        <div className="relative h-450px overflow-hidden">
            <img
                src={banner}
                alt="Banner"
                className="object-cover max-w-full h-full"
            />
            <div className="absolute inset-0 flex flex-col justify-center items-center text-white bg-black bg-opacity-10">
                <h1 className="text-5xl font-bold mb-4">{title}</h1>
            </div>
        </div>
    );
}

export default Banner;
