import { Menu, X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";

const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeNavbarOnResize = useCallback(() => {
        if (window.innerWidth >= 768 && isOpen) {
            setIsOpen(false);
        }
    }, [isOpen]);

    useEffect(() => {
        window.addEventListener('resize', closeNavbarOnResize);

        return () => {
            window.removeEventListener('resize', closeNavbarOnResize);
        };
    }, [isOpen, closeNavbarOnResize]);

    return (
        <nav className="flex">
            <div className="hidden w-full md:flex justify-between">
                <ScrollLink
                    to="main"
                    smooth={true}
                    duration={500}
                    offset={-112}
                    spy={true}
                    activeClass="bg-primary text-white px-3 py-2 rounded-b-md text-lg hover:text-white"
                    className="hover:text-primary px-3 py-2 text-lg"
                >
                    Main page
                </ScrollLink>
                <ScrollLink
                    to="about-us"
                    smooth={true}
                    duration={500}
                    offset={-112}
                    spy={true}
                    activeClass="bg-primary text-white px-3 py-2 rounded-b-md text-lg hover:text-white"
                    className="hover:text-primary px-3 py-2 text-lg"
                >
                    About us
                </ScrollLink>
                <ScrollLink
                    to="services"
                    smooth={true}
                    duration={500}
                    offset={-112}
                    spy={true}
                    activeClass="bg-primary text-white px-3 py-2 rounded-b-md text-lg hover:text-white"
                    className="hover:text-primary px-3 py-2 text-lg"
                >
                    Services
                </ScrollLink>
                <ScrollLink
                    to="contact"
                    smooth={true}
                    duration={500}
                    offset={-112}
                    spy={true}
                    activeClass="bg-primary text-white px-3 py-2 rounded-b-md text-lg hover:text-white"
                    className="hover:text-primary px-3 py-2 text-lg"
                >
                    Contact
                </ScrollLink>
            </div>
            <div className="md:hidden m-3">
                <button onClick={toggleMenu} className="bg-primary rounded-md p-2">
                    {isOpen ? <X className="text-white" /> : <Menu className="text-white" />}
                </button>
            </div>
            {isOpen && (
                <div className="absolute top-full left-0 w-full bg-white border border-gray-200">
                    <ScrollLink
                        to="main"
                        smooth={true}
                        duration={500}
                        offset={-112}
                        spy={true}
                        activeClass="bg-primary text-white px-3 py-2 rounded-md text-lg hover:text-white"
                        className="hover:text-primary px-3 py-2 text-lg block text-center"
                    >
                        Main page
                    </ScrollLink>
                    <ScrollLink
                        to="about-us"
                        smooth={true}
                        duration={500}
                        offset={-112}
                        spy={true}
                        activeClass="bg-primary text-white px-3 py-2 rounded-md text-lg hover:text-white"
                        className="hover:text-primary px-3 py-2 text-lg block text-center"
                    >
                        About us
                    </ScrollLink>
                    <ScrollLink
                        to="services"
                        smooth={true}
                        duration={500}
                        offset={-112}
                        spy={true}
                        activeClass="bg-primary text-white px-3 py-2 rounded-md text-lg hover:text-white"
                        className="hover:text-primary px-3 py-2 text-lg block text-center"
                    >
                        Services
                    </ScrollLink>
                    <ScrollLink
                        to="contact"
                        smooth={true}
                        duration={500}
                        offset={-112}
                        spy={true}
                        activeClass="bg-primary text-white px-3 py-2 rounded-md text-lg hover:text-white"
                        className="hover:text-primary px-3 py-2 text-lg block text-center"
                    >
                        Contact
                    </ScrollLink>
                </div>
            )}

        </nav>
    );
}

export default Nav;
